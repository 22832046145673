import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import SingleQuestion from '../components/SingleQuestion';
import AddButton from '../components/elements/AddButton';
import DeleteModal from '../components/DeleteModal';

import {
	setCurrentPage,
	setPageTitle,
	setLoading,
} from '../redux/actions/index';
import { loadQuestions } from '../redux/actions/faqActions';

function Faq(props) {
	const {
		setCurrentPage,
		setPageTitle,
		setLoading,
		loadQuestions,
		questions,
		showDeleteModal,
		selectedQuestion,
	} = props;

	const API_URL = process.env.REACT_APP_API_URL;

	useEffect(() => {
		setLoading(true);
		setCurrentPage('faq');
		setPageTitle('Frequently Asked Questions');
		loadQuestions();
		setLoading(false);
	}, [setCurrentPage, setPageTitle, setLoading, loadQuestions]);

	return (
		<div>
			{/* Delete Modal */}
			{showDeleteModal && (
				<DeleteModal
					text="question?"
					url={`${API_URL}/faqs/${selectedQuestion}`}
					errorMessage="Question could not be deleted."
				/>
			)}
			{/* Questions */}
			{questions.length === 0 ? (
				<p>No questions found.</p>
			) : (
				questions.map((question) => (
					<SingleQuestion key={question._id} question={question} />
				))
			)}
			{/* Add Button */}
			<AddButton text="Add new question" link="/faq/create" />
		</div>
	);
}

const mapStateToProps = (state) => ({
	questions: state.FaqReducer.questions,
	showDeleteModal: state.FaqReducer.showDeleteModal,
	selectedQuestion: state.FaqReducer.selectedQuestion,
});
const mapDispatchToProps = (dispatch) => ({
	setCurrentPage: (string) => dispatch(setCurrentPage(string)),
	setPageTitle: (string) => dispatch(setPageTitle(string)),
	setLoading: (bool) => dispatch(setLoading(bool)),

	loadQuestions: () => dispatch(loadQuestions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
