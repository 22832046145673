import React, { useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '../components/elements/Button';
import Input from '../components/elements/Input';
import Textarea from '../components/elements/Textarea';
import Switch from 'react-switch';
import ImageUploader from 'react-images-upload';
import Alert from '../components/Alert';

import {
	setCurrentPage,
	setPageTitle,
	setLoading,
	setAlert,
} from '../redux/actions/index';
import {
	setCategName,
	setCategNameErr,
	setCategAvail,
	setConvBody,
	setConvImage,
	setConvAudio,
	loadCategory,
} from '../redux/actions/categoriesActions';

function CreateEditCategory(props) {
	const {
		status,
		setCurrentPage,
		setPageTitle,
		setLoading,
		name,
		setCategName,
		nameError,
		setCategNameErr,
		premiumOnly,
		setCategAvail,
		conversationBody,
		setConvBody,
		conversationImage,
		setConvImage,
		conversationAudio,
		setConvAudio,
		alert,
		setAlert,
		loadCategory,
	} = props;

	const params = props.match.params;
	useEffect(() => {
		setLoading(true);
		// Active sidebar link
		setCurrentPage('categories');
		// Reset form
		setCategName('');
		setCategAvail(false);
		setConvBody('');
		setConvImage('');
		setConvAudio('');
		if (status === 'create') {
			setPageTitle('Add Category');
		}
		if (status === 'edit') {
			setPageTitle('Edit Category');
			loadCategory(params.id);
		}
		setLoading(false);
	}, [
		setCurrentPage,
		setPageTitle,
		setLoading,
		status,
		params,
		loadCategory,
		setCategName,
		setCategAvail,
		setConvBody,
		setConvImage,
		setConvAudio,
	]);

	const history = useHistory();
	const API_URL = process.env.REACT_APP_API_URL;

	const clearForm = () => {
		setAlert({ type: '', message: '' });
		setCategName('');
		setCategAvail(false);
		setConvBody('');
		setConvImage('');
		setConvAudio('');
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let activeErrors = false;
		if (name === '') {
			setCategNameErr('Error! You must insert a category name.');
			activeErrors = true;
		} else {
			setCategNameErr('');
		}

		if (!activeErrors) {
			const form = new FormData();
			form.append('title', name);
			form.append('subscription', premiumOnly ? 'premium' : 'free');
			conversationBody && form.append('conversation_body', conversationBody);
			conversationImage && form.append('conversation_image', conversationImage);
			conversationAudio && form.append('conversation_audio', conversationAudio);
			if (status === 'create') {
				axios
					.post(`${API_URL}/categories`, form, {
						withCredentials: true,
					})
					.then(() => {
						setAlert({ type: 'success', message: 'New category created' });
						setTimeout(() => {
							clearForm();
							history.push('/categories');
						}, 2500);
					})
					.catch(() => {
						setAlert({ type: 'error', message: 'Something went wrong.' });
						setTimeout(() => {
							setAlert({
								type: '',
								message: '',
							});
						}, 2500);
					});
			}
			if (status === 'edit') {
				axios
					.put(`${API_URL}/categories/${params.id}`, form, {
						withCredentials: true,
					})
					.then(() => {
						setAlert({ type: 'success', message: 'Category updated' });
						setTimeout(() => {
							clearForm();
							history.push('/categories');
						}, 2500);
					})
					.catch(() => {
						setAlert({ type: 'error', message: 'Something went wrong.' });
						setTimeout(() => {
							setAlert({
								type: '',
								message: '',
							});
						}, 2500);
					});
			}
		}
	};

	return (
		<div className="addCategory">
			<Alert type={alert.type} message={alert.message} />
			<form onSubmit={handleSubmit}>
				<Input
					type="text"
					id="title"
					name="title"
					label="Name"
					placeholder="New category name"
					value={name}
					onChange={(e) => setCategName(e.target.value)}
					error={nameError}
				/>
				<p className="mb-3">Premium only</p>
				<Switch
					onChange={() => setCategAvail(!premiumOnly)}
					checked={premiumOnly}
					checkedIcon={false}
					uncheckedIcon={false}
					onColor="#0b1542"
					offColor="#a4b0b6"
					name="subscription"
				/>
				<Textarea
					id="conversation_body"
					name="conversation_body"
					label="Conversation narration"
					placeholder="Your conversation narration"
					value={conversationBody}
					onChange={(e) => setConvBody(e.target.value)}
				/>
				<p className="mb-3 mt-5">Conversation Banner Image</p>
				<ImageUploader
					onChange={(images) =>
						images[0] ? setConvImage(images[0]) : setConvImage('')
					}
					imgExtension={['.jpg', '.gif', '.png', '.gif']}
					maxFileSize={5242880}
					singleImage={true}
					withIcon={false}
					buttonStyles={{ background: '#0b1542' }}
					buttonText="Choose image"
					label={
						conversationImage && conversationImage.name
							? conversationImage.name
							: conversationImage
							? conversationImage
							: 'Max file size: 5mb, accepted: jpg|gif|png'
					}
					name="conversation_image"
					fileContainerStyle={{
						boxShadow: 'none',
						borderRadius: '4px',
						border: '1px solid #d7e0e7',
					}}
				/>
				<p className="mb-3 mt-5">Conversation Audio File</p>
				<ImageUploader
					withIcon={false}
					onChange={(audios) => setConvAudio(audios[0])}
					imgExtension={['.mp3', '.mp4', '.wav', '.ogg']}
					maxFileSize={52500000}
					buttonStyles={{ background: '#0b1542' }}
					singleImage={true}
					label={
						conversationAudio && conversationAudio.name
							? conversationAudio.name
							: conversationAudio
							? conversationAudio
							: 'Max file size: 50mb, accepted: mp3|mp4|wav|ogg'
					}
					buttonText="Choose audio"
					accept="audio/*"
					name="conversation_audio"
					fileContainerStyle={{
						boxShadow: 'none',
						borderRadius: '4px',
						border: '1px solid #d7e0e7',
					}}
				/>
				<div className="d-flex my-5 saveCancelButtons">
					<Button type="submit" className="blueButton">
						Save
					</Button>
					<Button
						type="button"
						className="textLink faded hoverBlack ml-4"
						onClick={() => history.push('/categories')}
					>
						Cancel
					</Button>
				</div>
			</form>
		</div>
	);
}

const mapStateToProps = (state) => ({
	loading: state.RootReducer.loading,
	alert: state.RootReducer.alert,

	name: state.CategoriesReducer.name,
	nameError: state.CategoriesReducer.nameError,
	premiumOnly: state.CategoriesReducer.premiumOnly,
	conversationBody: state.CategoriesReducer.conversationBody,
	conversationImage: state.CategoriesReducer.conversationImage,
	conversationAudio: state.CategoriesReducer.conversationAudio,
});
const mapDispatchToProps = (dispatch) => ({
	setCurrentPage: (string) => dispatch(setCurrentPage(string)),
	setPageTitle: (string) => dispatch(setPageTitle(string)),
	setLoading: (bool) => dispatch(setLoading(bool)),
	setAlert: (obj) => dispatch(setAlert(obj)),

	setCategName: (string) => dispatch(setCategName(string)),
	setCategNameErr: (string) => dispatch(setCategNameErr(string)),
	setCategAvail: (string) => dispatch(setCategAvail(string)),
	setConvBody: (string) => dispatch(setConvBody(string)),
	setConvImage: (string) => dispatch(setConvImage(string)),
	setConvAudio: (string) => dispatch(setConvAudio(string)),
	loadCategory: (string) => dispatch(loadCategory(string)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditCategory);
