// Packages
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

// Components
import Input from '../elements/Input';
import Button from '../elements/Button';
// Actions
import { setAlert } from '../../redux/actions/index';
import {
	setEmail,
	setEmailErr,
	setPassword,
	setPasswordErr,
	setFormStep,
	setCredentials,
} from '../../redux/actions/authActions';

export const EmailForm = (props) => {
	// prettier-ignore
	const {setAlert, email, setEmail, emailErr, setEmailErr, password, setPassword, passwordErr, setPasswordErr, setFormStep, setCredentials } = props;

	const API_URL = process.env.REACT_APP_API_URL;

	// Form Submit
	const handleSubmit = async (e) => {
		e.preventDefault();
		let activeErrors = false;

		// Validate Email
		const emailRegex = /\S+@\S+\.\S+/;
		if (email === '') {
			setEmailErr('Error! You must insert your email');
			activeErrors = true;
		} else if (!emailRegex.test(email)) {
			setEmailErr('Error! Invalid email format');
			activeErrors = true;
		} else {
			setEmailErr('');
			activeErrors = false;
		}

		// Validate Password
		if (password === '') {
			setPasswordErr('Error! You must insert your password');
			activeErrors = true;
		} else {
			setPasswordErr('');
			activeErrors = false;
		}

		// Submit
		if (!activeErrors) {
			axios
				.post(`${API_URL}/auth/login`, {
					email,
					password,
				})
				.then(
					(res) => {
						// Check if role is admin
						if (res.status === 200 && res.data.user.role === 'customer') {
							return setAlert({
								type: 'error',
								message: "This account can't be used here",
							});
						} else {
							// Go to step 2
							setCredentials(res.data.user);
							return setFormStep(2);
						}
					},
					(err) => {
						// Wrong credentials
						if (err.response.status === 401) {
							return setAlert({
								type: 'error',
								message: 'Invalid credentials.',
							});
						} else {
							return setAlert({
								type: 'error',
								message: 'Something went wrong',
							});
						}
					}
				);
		}
		// Reset alert
		setAlert({ type: '', message: '' });
	};

	return (
		<form className="loginPage__form" onSubmit={handleSubmit} noValidate>
			{/* Email & Pass Auth */}
			<Input
				type="email"
				id="email"
				name="email"
				label="Email"
				placeholder="Your email"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				error={emailErr}
			/>
			<Input
				type="password"
				id="password"
				name="password"
				label="Password"
				placeholder="Your password"
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				error={passwordErr}
			/>
			<div className="text-center">
				<Button type="submit" className="whiteButton mt-4">
					Log In
				</Button>
			</div>
		</form>
	);
};

const mapStateToProps = (state) => ({
	email: state.AuthReducer.email,
	emailErr: state.AuthReducer.emailErr,
	password: state.AuthReducer.password,
	passwordErr: state.AuthReducer.passwordErr,
});

const mapDispatchToProps = (dispatch) => ({
	setAlert: (obj) => dispatch(setAlert(obj)),
	setEmail: (string) => dispatch(setEmail(string)),
	setEmailErr: (string) => dispatch(setEmailErr(string)),
	setPassword: (string) => dispatch(setPassword(string)),
	setPasswordErr: (string) => dispatch(setPasswordErr(string)),
	setFormStep: (string) => dispatch(setFormStep(string)),
	setCredentials: (obj) => dispatch(setCredentials(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailForm);
