import {
	SET_EMAIL,
	SET_EMAIL_ERR,
	SET_PASSWORD,
	SET_PASSWORD_ERR,
	SET_FORM_STEP,
	SET_TOKEN,
	SET_TOKEN_ERR,
	SET_CREDENTIALS,
	SET_SESSION,
} from '../constants/action-types';

const initialState = {
	email: '',
	emailErr: '',
	password: '',
	passwordErr: '',
	formStep: 1,
	credentials: null,
	token: '',
	tokenErr: '',
	session: null,
};

function AuthReducer(state = initialState, action) {
	switch (action.type) {
		case SET_EMAIL:
			return { ...state, email: action.payload };
		case SET_EMAIL_ERR:
			return { ...state, emailErr: action.payload };
		case SET_PASSWORD:
			return { ...state, password: action.payload };
		case SET_PASSWORD_ERR:
			return { ...state, passwordErr: action.payload };
		case SET_FORM_STEP:
			return { ...state, formStep: action.payload };
		case SET_CREDENTIALS:
			return { ...state, credentials: action.payload };
		case SET_TOKEN:
			return { ...state, token: action.payload };
		case SET_TOKEN_ERR:
			return { ...state, tokenErr: action.payload };
		case SET_SESSION:
			return { ...state, session: action.payload };
		default:
			return state;
	}
}

export default AuthReducer;
