import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import SingleUser from '../components/SingleUser';
import SearchUsers from '../components/SearchUsers';

import {
	setCurrentPage,
	setPageTitle,
	setLoading,
} from '../redux/actions/index';
import { loadUsers } from '../redux/actions/usersActions';

function Users(props) {
	const { setCurrentPage, setPageTitle, setLoading } = props;
	const { users, loadUsers, searchResults } = props;

	const premiumUsers = users.filter((user) => user.subscription === 'premium');
	const totalUsers = users.length;
	const totalPremium = premiumUsers.length;

	useEffect(() => {
		setLoading(true);
		setCurrentPage('users');
		setPageTitle(`Users - ${totalUsers} total, ${totalPremium} premium`);
		loadUsers();
		setLoading(false);
	}, [
		setCurrentPage,
		setPageTitle,
		setLoading,
		loadUsers,
		totalUsers,
		totalPremium,
	]);

	return (
		<div className="users">
			{/* Search component */}
			<SearchUsers />
			{/* Categories list */}
			{users.length === 0 ? (
				<p>No users found.</p>
			) : searchResults.length === 0 ? (
				<p>No users found.</p>
			) : (
				searchResults.map((user) => <SingleUser key={user._id} user={user} />)
			)}
		</div>
	);
}

const mapStateToProps = (state) => ({
	loading: state.RootReducer.loading,
	users: state.UsersReducer.users,
	searchResults: state.UsersReducer.searchResults,
});
const mapDispatchToProps = (dispatch) => ({
	setCurrentPage: (string) => dispatch(setCurrentPage(string)),
	setPageTitle: (string) => dispatch(setPageTitle(string)),
	setLoading: (bool) => dispatch(setLoading(bool)),

	loadUsers: () => dispatch(loadUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
