import React from 'react';
import { connect } from 'react-redux';
import Button from './elements/Button';

import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import {
	toggleDeleteModal,
	setSelectedQuestion,
} from '../redux/actions/faqActions';

function SingleQuestion(props) {
	const { question, setSelectedQuestion, toggleDeleteModal } = props;

	const history = useHistory();

	return (
		<div className="singleQuestion">
			<div className="singleQuestion__section">
				<p className="singleQuestion__label">Question</p>
				<p className="singleQuestion__content">{question.question}</p>
			</div>
			<div className="singleQuestion__section">
				<p className="singleQuestion__label">Answer</p>
				<p className="singleQuestion__content">{question.answer}</p>
			</div>
			<div className="singleQuestion__buttons">
				<Button
					type="button"
					icon={faEdit}
					className="textLink hoverEdit"
					onClick={() => history.push(`/faq/${question._id}/edit`)}
				>
					Edit
				</Button>
				<Button
					type="button"
					icon={faTimes}
					className="textLink hoverDelete"
					onClick={() => {
						setSelectedQuestion(question._id);
						toggleDeleteModal(true);
					}}
				>
					Delete
				</Button>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	selectedQuestion: state.FaqReducer.selectedQuestion,
});

const mapDispatchToProps = (dispatch) => ({
	toggleDeleteModal: (bool) => dispatch(toggleDeleteModal(bool)),
	setSelectedQuestion: (string) => dispatch(setSelectedQuestion(string)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleQuestion);
