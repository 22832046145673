import {
	LOAD_QUESTIONS,
	TOGGLE_DELETE_MODAL,
	SET_SELECTED_QUESTION,
	LOAD_SINGLE_QUESTION,
} from '../constants/action-types';

import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export function loadQuestions() {
	return function (dispatch) {
		return axios
			.get(`${API_URL}/faqs/`, {
				withCredentials: true,
			})
			.then((response) =>
				response.status === 200 ? response.data.questions : []
			)
			.then((payload) => {
				dispatch({ type: LOAD_QUESTIONS, payload });
			});
	};
}
export function toggleDeleteModal(payload) {
	return { type: TOGGLE_DELETE_MODAL, payload };
}
export function setSelectedQuestion(payload) {
	return { type: SET_SELECTED_QUESTION, payload };
}
export function loadSingleQuestion(payload) {
	return function (dispatch) {
		return axios
			.get(`${API_URL}/faqs/${payload}`, {
				withCredentials: true,
			})
			.then((response) =>
				response.status === 200 ? response.data.question : null
			)
			.then((payload) => {
				dispatch({ type: LOAD_SINGLE_QUESTION, payload });
			});
	};
}
