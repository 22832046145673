import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../components/elements/Button';
import { faEye, faUserMd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SingleUser(props) {
	const { user } = props;
	const history = useHistory();
	return (
		<div className="singleUser">
			<p className="singleUser__email">{user.email}</p>
			<div className="singleUser__rightSide">
				<p className="singleUser__premium">
					<FontAwesomeIcon icon={faUserMd} /> Premium:{' '}
					<em>{user.subscription === 'free' ? 'No' : 'Yes'}</em>
				</p>
				<Button
					className="textLink hoverAccent"
					icon={faEye}
					onClick={() => history.push(`/users/${user._id}`)}
				>
					View profile
				</Button>
			</div>
		</div>
	);
}

export default SingleUser;
