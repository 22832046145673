import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import moment from 'moment';

import DataCard from '../components/DataCard';

import {
	setCurrentPage,
	setPageTitle,
	setLoading,
} from '../redux/actions/index';
import { loadCategories } from '../redux/actions/categoriesActions';
import { loadUsers, loadUserStats } from '../redux/actions/usersActions';
import { loadAllPhrases } from '../redux/actions/phrasesActions';

import {
	faBookOpen,
	faAlignJustify,
	faUser,
	faUserMd,
	faChartLine,
} from '@fortawesome/free-solid-svg-icons';

function Dashboard(props) {
	const {
		setCurrentPage,
		setPageTitle,
		setLoading,
		loadCategories,
		loadUsers,
		loadAllPhrases,
		categories,
		users,
		allPhrases,
		loadUsersStats,
		usersStats,
	} = props;

	useEffect(() => {
		setLoading(true);
		setCurrentPage('dashboard');
		setPageTitle('Dashboard');
		loadCategories();
		loadUsers();
		loadUsersStats();
		loadAllPhrases();
		setLoading(false);
	}, [
		setCurrentPage,
		setPageTitle,
		setLoading,
		loadCategories,
		loadUsers,
		loadAllPhrases,
		loadUsersStats,
	]);

	// Cards data
	const totalCategories = categories.length;
	const totalPhrases = allPhrases.length;
	const totalUsers = users.length;
	const premiumUsers = users.filter((user) => user.subscription === 'premium');
	const totalPremiumUsers = premiumUsers.length;
	const activeUsers = users.filter((user) => {
		const last_active = moment(user.last_active);
		const now = moment(Date.now());
		const duration = moment.duration(now.diff(last_active, 'days'));
		return duration < 14;
	});
	const activePercentage = ((activeUsers.length / totalUsers) * 100).toFixed(1);

	const [options, setOptions] = useState({});
	const [series, setSeries] = useState([]);
	// Set user graph data
	useEffect(() => {
		let dates = [];
		usersStats.forEach((stat) => {
			dates.push(moment(stat.date).add(-1, 'day').format('DD/MM/YYYY'));
		});
		setOptions({ xaxis: { categories: dates } });

		let dailyTotalUsers = [];
		usersStats.forEach((stat) => dailyTotalUsers.push(stat.users));
		setSeries([{ name: 'Users', data: dailyTotalUsers }]);
	}, [usersStats]);

	return (
		<div className="dashboard">
			<div className="dashboard__cards">
				<DataCard label="Phrases" data={totalPhrases} icon={faBookOpen} />
				<DataCard
					label="Categories"
					data={totalCategories}
					icon={faAlignJustify}
				/>
				<DataCard label="Total users" data={totalUsers} icon={faUser} />
				<DataCard
					label="Active Users"
					data={`${activePercentage}%`}
					icon={faChartLine}
				/>
				<DataCard
					label="Premium Users"
					data={totalPremiumUsers}
					icon={faUserMd}
				/>
			</div>
			<h2 className="mb-5">Users growth map</h2>
			<div className="dashboard__chart">
				<Chart
					options={options}
					series={series}
					type="line"
					width={'97%'}
					height={320}
				/>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	loading: state.RootReducer.loading,
	categories: state.CategoriesReducer.categories,
	users: state.UsersReducer.users,
	allPhrases: state.PhrasesReducer.allPhrases,
	usersStats: state.UsersReducer.usersStats,
});
const mapDispatchToProps = (dispatch) => ({
	setCurrentPage: (string) => dispatch(setCurrentPage(string)),
	setPageTitle: (string) => dispatch(setPageTitle(string)),
	setLoading: (bool) => dispatch(setLoading(bool)),

	loadCategories: () => dispatch(loadCategories()),
	loadAllPhrases: () => dispatch(loadAllPhrases()),
	loadUsers: () => dispatch(loadUsers()),
	loadUsersStats: () => dispatch(loadUserStats()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
