import {
	LOAD_CATEGORIES,
	LOAD_CATEGORY,
	SET_CATEG_NAME,
	SET_CATEG_NAME_ERR,
	SET_CATEG_AVAIL,
	SET_CONV_BODY,
	SET_CONV_IMAGE,
	SET_CONV_AUDIO,
	TOGGLE_DELETE_MODAL,
	SET_SELECTED_CATEGORY,
} from '../constants/action-types';

import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export function loadCategories() {
	return function (dispatch) {
		return axios
			.get(`${API_URL}/categories`, { withCredentials: true })
			.then((response) =>
				response.status === 200 ? response.data.categories : []
			)
			.then((payload) => {
				dispatch({ type: LOAD_CATEGORIES, payload });
			});
	};
}

export function loadCategory(payload) {
	return function (dispatch) {
		return axios
			.get(`${API_URL}/categories/${payload}`, { withCredentials: true })
			.then((response) => response.status === 200 && response.data.category)
			.then((payload) => {
				dispatch({ type: LOAD_CATEGORY, payload });
			});
	};
}

export function setCategName(payload) {
	return { type: SET_CATEG_NAME, payload };
}
export function setCategNameErr(payload) {
	return { type: SET_CATEG_NAME_ERR, payload };
}
export function setCategAvail(payload) {
	return { type: SET_CATEG_AVAIL, payload };
}
export function setConvBody(payload) {
	return { type: SET_CONV_BODY, payload };
}
export function setConvImage(payload) {
	return { type: SET_CONV_IMAGE, payload };
}
export function setConvAudio(payload) {
	return { type: SET_CONV_AUDIO, payload };
}
export function toggleDeleteModal(payload) {
	return { type: TOGGLE_DELETE_MODAL, payload };
}
export function setSelectedCategory(payload) {
	return { type: SET_SELECTED_CATEGORY, payload };
}
