import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '../components/elements/Button';
import Textarea from '../components/elements/Textarea';
import Input from '../components/elements/Input';
import Alert from '../components/Alert';

import {
	setCurrentPage,
	setPageTitle,
	setLoading,
	setAlert,
} from '../redux/actions/index';
import { loadSingleQuestion } from '../redux/actions/faqActions';

function CreateEditQuestion(props) {
	const {
		status,
		setCurrentPage,
		setPageTitle,
		setLoading,
		alert,
		setAlert,
		loadSingleQuestion,
		singleQuestion,
	} = props;

	const [question, setQuestion] = useState('');
	const [questionError, setQuestionError] = useState('');
	const [answer, setAnswer] = useState('');
	const [answerError, setAnswerError] = useState('');

	const params = props.match.params;

	useEffect(() => {
		setLoading(true);
		// Active sidebar link
		setCurrentPage('categories');
		// Reset form
		setAlert({ type: '', message: '' });
		if (status === 'create') {
			setPageTitle('Add Question');
		}
		if (status === 'edit') {
			setPageTitle('Edit Question');
			loadSingleQuestion(params.id);
		}
		setLoading(false);
	}, [
		setLoading,
		setCurrentPage,
		setPageTitle,
		status,
		setAlert,
		loadSingleQuestion,
		params,
	]);

	useEffect(() => {
		if (status === 'edit' && singleQuestion) {
			setQuestion(singleQuestion.question);
			setAnswer(singleQuestion.answer);
		}
	}, [setQuestion, setAnswer, singleQuestion, status]);

	const history = useHistory();
	const API_URL = process.env.REACT_APP_API_URL;

	const handleSubmit = (e) => {
		e.preventDefault();
		let activeErrors = false;
		// Question validation
		if (question === '') {
			setQuestionError("Error! You can't have an empty phrase");
			activeErrors = true;
		} else {
			setQuestionError('');
		}
		// Answer validation
		if (answer === '') {
			setAnswerError("Error! You can't have an empty phrase");
			activeErrors = true;
		} else {
			setAnswerError('');
		}

		if (!activeErrors) {
			const form = { question, answer };

			if (status === 'create') {
				axios
					.post(`${API_URL}/faqs`, form, {
						withCredentials: true,
					})
					.then(() => {
						setAlert({ type: 'success', message: 'New question created' });
						setTimeout(() => {
							setQuestion('');
							setAnswer('');
							history.push(`/faq`);
						}, 2500);
					})
					.catch(() => {
						setAlert({ type: 'error', message: 'Something went wrong.' });
						setTimeout(() => {
							setAlert({
								type: '',
								message: '',
							});
						}, 2500);
					});
			}
			if (status === 'edit') {
				axios
					.put(`${API_URL}/faqs/${params.id}`, form, {
						withCredentials: true,
					})
					.then(() => {
						setAlert({ type: 'success', message: 'Question updated' });
						setTimeout(() => {
							setQuestion('');
							setAnswer('');
							history.push(`/faq`);
						}, 2500);
					})
					.catch(() => {
						setAlert({ type: 'error', message: 'Something went wrong.' });
						setTimeout(() => {
							setAlert({
								type: '',
								message: '',
							});
						}, 2500);
					});
			}
		}
	};

	return (
		<div className="addQuestion">
			<Alert type={alert.type} message={alert.message} />
			<form onSubmit={handleSubmit}>
				<Input
					id="question"
					name="question"
					placeholder="Your new question"
					value={question}
					label="Question"
					onChange={(e) => setQuestion(e.target.value)}
					error={questionError}
				/>
				<Textarea
					id="answer"
					name="answer"
					placeholder="Your answer"
					value={answer}
					label="Answer"
					onChange={(e) => setAnswer(e.target.value)}
					error={answerError}
				/>
				<div className="d-flex my-5 saveCancelButtons">
					<Button type="submit" className="blueButton">
						Save
					</Button>
					<Button
						type="button"
						className="textLink faded hoverBlack ml-4"
						onClick={() => history.push(`/faq`)}
					>
						Cancel
					</Button>
				</div>
			</form>
		</div>
	);
}

const mapStateToProps = (state) => ({
	loading: state.RootReducer.loading,
	alert: state.RootReducer.alert,

	singleQuestion: state.FaqReducer.question,
});
const mapDispatchToProps = (dispatch) => ({
	setCurrentPage: (string) => dispatch(setCurrentPage(string)),
	setPageTitle: (string) => dispatch(setPageTitle(string)),
	setLoading: (bool) => dispatch(setLoading(bool)),
	setAlert: (obj) => dispatch(setAlert(obj)),

	loadSingleQuestion: (string) => dispatch(loadSingleQuestion(string)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditQuestion);
