import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Loading from '../components/Loading';
import Button from '../components/elements/Button';
import DeleteModal from '../components/DeleteModal';

import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';

import {
	setCurrentPage,
	setPageTitle,
	setLoading,
} from '../redux/actions/index';
import {
	loadSingleUser,
	toggleDeleteModal,
} from '../redux/actions/usersActions';

function User(props) {
	const {
		setLoading,
		setPageTitle,
		setCurrentPage,
		loadSingleUser,
		singleUser,
		showDeleteModal,
		toggleDeleteModal,
	} = props;

	const id = props.match.params.id;
	const history = useHistory();
	const API_URL = process.env.REACT_APP_API_URL;

	useEffect(() => {
		setLoading(true);
		setPageTitle('User Profile');
		setCurrentPage('users');
		loadSingleUser(id);
		setLoading(false);
	}, [setLoading, setPageTitle, setCurrentPage, id, loadSingleUser]);

	if (!singleUser) {
		return <Loading />;
	} else {
		const usage_history = moment
			.utc(
				moment.duration(singleUser.usage_history, 'seconds').as('milliseconds')
			)
			.format('HH:mm:ss');
		// Active / Inactive
		const last_active = moment(singleUser.last_active);
		const now = moment(Date.now());
		const duration = moment.duration(now.diff(last_active, 'days'));
		return (
			<div className="userProfile">
				{/* Delete Modal */}
				{showDeleteModal && (
					<DeleteModal
						text="user? Be careful! It can't be reversed!"
						url={`${API_URL}/users/${singleUser._id}`}
						redirectURL={'/users'}
						errorMessage="User could not be deleted."
					/>
				)}
				{/* Content */}
				<section className="userProfile__section">
					<p className="userProfile__sectionTitle">User Details</p>
					<div className="userProfile__fieldContainer">
						<p className="userProfile__fieldLabel">Name</p>
						<p className="userProfile__value">{singleUser.name}</p>
					</div>
					<div className="userProfile__fieldContainer">
						<p className="userProfile__fieldLabel">Email</p>
						<p className="userProfile__value">{singleUser.email}</p>
					</div>
					<div className="userProfile__fieldContainer">
						<p className="userProfile__fieldLabel">Grade</p>
						<p className="userProfile__value">{singleUser.grade}</p>
					</div>
					<div className="userProfile__fieldContainer">
						<p className="userProfile__fieldLabel">Specialty</p>
						<p className="userProfile__value">{singleUser.speciality}</p>
					</div>
					<div className="userProfile__fieldContainer">
						<p className="userProfile__fieldLabel">Password</p>
						<p className="userProfile__value">***********</p>
					</div>
				</section>
				<section className="userProfile__section">
					<p className="userProfile__sectionTitle">Preferences</p>
					<div className="userProfile__fieldContainer">
						<p className="userProfile__fieldLabel">Text-to-Speech Accent</p>
						<p className="userProfile__value">{singleUser.tts_accent.label}</p>
					</div>
				</section>
				<section className="userProfile__section">
					<p className="userProfile__sectionTitle">Usage History</p>
					<div className="userProfile__fieldContainer">
						<p className="userProfile__fieldLabel">Time Spent in the App</p>
						<p className="userProfile__value">{usage_history}</p>
					</div>
				</section>
				<section className="userProfile__section">
					<p className="userProfile__sectionTitle">Status</p>
					<div className="userProfile__fieldContainer">
						<p className="userProfile__fieldLabel">
							{duration < 14 ? 'Active' : 'Inactive'} member
						</p>
					</div>
				</section>
				<section className="userProfile__section">
					<p className="userProfile__sectionTitle">Subscription</p>
					<div className="userProfile__fieldContainer">
						<p className="userProfile__fieldLabel">
							{singleUser.subscription === 'free'
								? 'Free Tier'
								: 'Premium Tier'}
						</p>
					</div>
				</section>
				<div className="userProfile__buttons">
					<Button
						className="textLink hoverEdit"
						icon={faEdit}
						onClick={() => history.push(`/users/${singleUser._id}/edit`)}
					>
						Edit
					</Button>
					<Button
						className="textLink hoverDelete"
						icon={faTimes}
						onClick={() => {
							toggleDeleteModal(true);
						}}
					>
						Delete
					</Button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	singleUser: state.UsersReducer.singleUser,
	showDeleteModal: state.UsersReducer.showDeleteModal,
});
const mapDispatchToProps = (dispatch) => ({
	setLoading: (bool) => dispatch(setLoading(bool)),
	setCurrentPage: (string) => dispatch(setCurrentPage(string)),
	setPageTitle: (string) => dispatch(setPageTitle(string)),
	loadSingleUser: (string) => dispatch(loadSingleUser(string)),
	toggleDeleteModal: (bool) => dispatch(toggleDeleteModal(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
