import React, { useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTachometerAlt,
	faChevronRight,
	faChevronLeft,
	faAlignJustify,
	faUser,
	faQuestion,
} from '@fortawesome/free-solid-svg-icons';

import { toggleSidebar, setAlert } from '../redux/actions/index';
import { setSession } from '../redux/actions/authActions';

function Sidebar(props) {
	const {
		openSidebar,
		toggleSidebar,
		currentPage,
		setSession,
		setAlert,
		layout,
		title,
	} = props;

	const API_URL = process.env.REACT_APP_API_URL;

	const logout = async () => {
		await axios
			.get(`${API_URL}/auth/logout`, { withCredentials: true })
			.then(() => {
				setAlert({ type: 'success', message: "You've been logged out" });
				setTimeout(() => {
					setSession(null);
					setAlert({ type: '', message: '' });
				}, 2500);
			})
			.catch(() => {
				setAlert({ type: 'error', message: 'Something went wrong' });
			});
	};

	// Show / Hide Sidebar
	useEffect(() => {
		const openCloseSidebar = () => {
			const width = window.innerWidth;
			if (width <= 1200) {
				toggleSidebar(false);
			} else {
				toggleSidebar(true);
			}
		};
		openCloseSidebar();
		window.addEventListener('resize', () => openCloseSidebar());
	}, [toggleSidebar]);

	return (
		<div
			className={`sidebar ${!openSidebar && 'hiddenSidebar'} ${
				layout === 'public' && 'publicSidebar'
			}`}
		>
			{/* Show / Hide Sidebar */}
			<button
				className="sidebar__toggle"
				onClick={() => toggleSidebar(!openSidebar)}
			>
				<FontAwesomeIcon
					icon={openSidebar ? faChevronLeft : faChevronRight}
					className="sidebar__toggle--icon"
				/>
				<span className="sidebar__toggle--text">
					{openSidebar ? 'Hide' : 'Show'}
				</span>
			</button>

			{/* Header */}
			<div className="sidebar__header">
				<div className="sidebar__header--logo"></div>
				<h2 className="sidebar__header--title">
					{layout === 'public' && title ? title : 'Admin Area'}
				</h2>
			</div>

			{/* Links */}
			{layout === 'main' && (
				<>
					<ul className="sidebar__links">
						{/* Dashboard */}
						<li>
							<Link
								to="/"
								className={`sidebar__link ${
									currentPage === 'dashboard' && 'active'
								}`}
								onClick={() =>
									window.innerWidth <= 1200 && toggleSidebar(false)
								}
							>
								<div className="sidebar__link--left">
									<FontAwesomeIcon icon={faTachometerAlt} />
									<span>Dashboard</span>
								</div>
								<div className="sidebar__link--right">
									<FontAwesomeIcon icon={faChevronRight} />
								</div>
							</Link>
						</li>
						{/* Categories */}
						<li>
							<Link
								to="/categories"
								className={`sidebar__link ${
									currentPage === 'categories' && 'active'
								}`}
								onClick={() =>
									window.innerWidth <= 1200 && toggleSidebar(false)
								}
							>
								<div className="sidebar__link--left">
									<FontAwesomeIcon icon={faAlignJustify} />
									<span>Categories</span>
								</div>
								<div className="sidebar__link--right">
									<FontAwesomeIcon icon={faChevronRight} />
								</div>
							</Link>
						</li>
						{/* Users */}
						<li>
							<Link
								to="/users"
								className={`sidebar__link ${
									currentPage === 'users' && 'active'
								}`}
								onClick={() =>
									window.innerWidth <= 1200 && toggleSidebar(false)
								}
							>
								<div className="sidebar__link--left">
									<FontAwesomeIcon icon={faUser} />
									<span>Users</span>
								</div>
								<div className="sidebar__link--right">
									<FontAwesomeIcon icon={faChevronRight} />
								</div>
							</Link>
						</li>
						{/* FAQ */}
						<li>
							<Link
								to="/faq"
								className={`sidebar__link ${currentPage === 'faq' && 'active'}`}
								onClick={() =>
									window.innerWidth <= 1200 && toggleSidebar(false)
								}
							>
								<div className="sidebar__link--left">
									<FontAwesomeIcon icon={faQuestion} />
									<span>FAQ</span>
								</div>
								<div className="sidebar__link--right">
									<FontAwesomeIcon icon={faChevronRight} />
								</div>
							</Link>
						</li>
					</ul>
					<button className="sidebar__logoutBtn" onClick={logout}>
						Log out
					</button>
				</>
			)}
		</div>
	);
}

const mapStateToProps = (state) => ({
	openSidebar: state.RootReducer.openSidebar,
	currentPage: state.RootReducer.currentPage,
	loading: state.RootReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
	toggleSidebar: (bool) => dispatch(toggleSidebar(bool)),
	setSession: (obj) => dispatch(setSession(obj)),
	setAlert: (obj) => dispatch(setAlert(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
