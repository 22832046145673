import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import MainLayout from '../layout/MainLayout';
import PublicLayout from '../layout/PublicLayout';

import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Categories from '../pages/Categories';
import CreateEditCategory from '../pages/CreateEditCategory';
import Phrases from '../pages/Phrases';
import CreateEditPhrase from '../pages/CreateEditPhrase';
import Users from '../pages/Users';
import User from '../pages/User';
import EditUser from '../pages/EditUser';
import Faq from '../pages/Faq';
import CreateEditFaq from '../pages/CreateEditFaq';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import DeleteAccount from '../pages/DeleteAccount';

import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';

import { setLoading } from '../redux/actions/index';
import { setSession } from '../redux/actions/authActions';

function App(props) {
	const { setSession, setLoading, session } = props;
	const API_URL = process.env.REACT_APP_API_URL;

	// Add session to Redux
	useEffect(() => {
		axios
			.get(`${API_URL}/auth/check-session`, { withCredentials: true })
			.then((res) => {
				setSession(res.data.user);
				setLoading(false);
			})
			.catch(() => setLoading(false));
	}, [API_URL, setSession, setLoading]);

	return (
		<Router>
			<Switch>
				<PublicRoute restricted={true} exact path="/login" component={Login} />
				<PublicRoute
					restricted={true}
					exact
					path="/privacy-policy"
					component={PrivacyPolicy}
				>
					<PublicLayout title="Privacy Policy">
						<PrivacyPolicy />
					</PublicLayout>
				</PublicRoute>
				<PublicRoute
					restricted={true}
					exact
					path="/delete-account"
					component={DeleteAccount}
				>
					<PublicLayout title="Delete Account">
						<DeleteAccount />
					</PublicLayout>
				</PublicRoute>
				{!session && (
					<PublicRoute path="*">
						<Redirect to="/login" />
					</PublicRoute>
				)}

				{/* Main Layout */}
				<MainLayout>
					{/* Dashboard routes */}
					<PrivateRoute exact path="/" component={Dashboard} />

					{/* Categories routes */}
					<PrivateRoute exact path="/categories" component={Categories} />
					<PrivateRoute
						exact
						path="/categories/create"
						component={CreateEditCategory}
						status="create"
					/>
					<PrivateRoute
						exact
						path="/categories/:id/edit"
						component={CreateEditCategory}
						status="edit"
					/>

					{/* Phrases routes */}
					<PrivateRoute
						exact
						path="/categories/:id/phrases"
						component={Phrases}
					/>
					<PrivateRoute
						exact
						path="/categories/:id/phrases/create"
						component={CreateEditPhrase}
						status="create"
					/>
					<PrivateRoute
						exact
						path="/categories/:categ_id/phrases/:ph_id/edit"
						component={CreateEditPhrase}
						status="edit"
					/>

					{/* Users routes */}
					<PrivateRoute exact path="/users" component={Users} />
					<PrivateRoute exact path="/users/:id" component={User} />
					<PrivateRoute exact path="/users/:id/edit" component={EditUser} />

					{/* Faq routes */}
					<PrivateRoute exact path="/faq" component={Faq} />
					<PrivateRoute
						exact
						path="/faq/create"
						component={CreateEditFaq}
						status="create"
					/>
					<PrivateRoute
						exact
						path="/faq/:id/edit"
						component={CreateEditFaq}
						status="edit"
					/>
				</MainLayout>
			</Switch>
		</Router>
	);
}
const mapStateToProps = (state) => ({
	session: state.AuthReducer.session,
});
const mapDispatchToProps = (dispatch) => ({
	setSession: (obj) => dispatch(setSession(obj)),
	setLoading: (bool) => dispatch(setLoading(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
