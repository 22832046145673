import React, { useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '../components/elements/Button';
import Textarea from '../components/elements/Textarea';
import Alert from '../components/Alert';

import {
	setCurrentPage,
	setPageTitle,
	setLoading,
	setAlert,
} from '../redux/actions/index';
import {
	setPhrase,
	setPhraseError,
	loadPhrase,
} from '../redux/actions/phrasesActions';

function CreateEditPhrase(props) {
	const {
		status,
		setCurrentPage,
		setPageTitle,
		setLoading,
		alert,
		setAlert,
		phrase,
		setPhrase,
		phraseError,
		setPhraseError,
		loadPhrase,
	} = props;

	const params = props.match.params;

	useEffect(() => {
		setLoading(true);
		// Active sidebar link
		setCurrentPage('categories');
		// Reset form
		setAlert({ type: '', message: '' });
		setPhrase('');
		setPhraseError('');
		if (status === 'create') {
			setPageTitle('Add Phrase');
		}
		if (status === 'edit') {
			setPageTitle('Edit Phrase');
			loadPhrase(params.ph_id);
		}
		setLoading(false);
	}, [
		setLoading,
		setCurrentPage,
		setPhrase,
		setPhraseError,
		setPageTitle,
		status,
		loadPhrase,
		params.ph_id,
		setAlert,
	]);

	const history = useHistory();
	const API_URL = process.env.REACT_APP_API_URL;

	const clearForm = () => {
		setAlert({ type: '', message: '' });
		setPhrase('');
		setPhraseError('');
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let activeErrors = false;
		if (phrase === '') {
			setPhraseError("Error! You can't have an empty phrase");
			activeErrors = true;
		} else {
			setPhraseError('');
		}

		if (!activeErrors) {
			const form = { category_id: params.id, body: phrase };

			if (status === 'create') {
				axios
					.post(`${API_URL}/phrases`, form, {
						withCredentials: true,
					})
					.then(() => {
						setAlert({ type: 'success', message: 'New phrase created' });
						setTimeout(() => {
							clearForm();
							history.push(`/categories/${params.id}/phrases`);
						}, 2500);
					})
					.catch(() => {
						setAlert({ type: 'error', message: 'Something went wrong.' });
						setTimeout(() => {
							setAlert({
								type: '',
								message: '',
							});
						}, 2500);
					});
			}
			if (status === 'edit') {
				axios
					.put(`${API_URL}/phrases/${params.ph_id}`, form, {
						withCredentials: true,
					})
					.then(() => {
						setAlert({ type: 'success', message: 'Phrase updated' });
						setTimeout(() => {
							clearForm();
							history.push(`/categories/${params.categ_id}/phrases`);
						}, 2500);
					})
					.catch(() => {
						setAlert({ type: 'error', message: 'Something went wrong.' });
						setTimeout(() => {
							setAlert({
								type: '',
								message: '',
							});
						}, 2500);
					});
			}
		}
	};

	return (
		<div className="addCategory">
			<Alert type={alert.type} message={alert.message} />
			<form onSubmit={handleSubmit}>
				<Textarea
					id="conversation_body"
					name="conversation_body"
					placeholder="Your new phrase"
					value={phrase}
					onChange={(e) => setPhrase(e.target.value)}
					error={phraseError}
				/>
				<div className="d-flex my-5 saveCancelButtons">
					<Button type="submit" className="blueButton">
						Save
					</Button>
					<Button
						type="button"
						className="textLink faded hoverBlack ml-4"
						onClick={() => history.push(`/categories/${params.id}/phrases`)}
					>
						Cancel
					</Button>
				</div>
			</form>
		</div>
	);
}

const mapStateToProps = (state) => ({
	loading: state.RootReducer.loading,
	alert: state.RootReducer.alert,

	phrase: state.PhrasesReducer.phrase,
	phraseError: state.PhrasesReducer.phraseError,
});
const mapDispatchToProps = (dispatch) => ({
	setCurrentPage: (string) => dispatch(setCurrentPage(string)),
	setPageTitle: (string) => dispatch(setPageTitle(string)),
	setLoading: (bool) => dispatch(setLoading(bool)),
	setAlert: (obj) => dispatch(setAlert(obj)),

	setPhrase: (string) => dispatch(setPhrase(string)),
	setPhraseError: (string) => dispatch(setPhraseError(string)),
	loadPhrase: (string) => dispatch(loadPhrase(string)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditPhrase);
