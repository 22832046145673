import {
	SET_EMAIL,
	SET_EMAIL_ERR,
	SET_PASSWORD,
	SET_PASSWORD_ERR,
	SET_FORM_STEP,
	SET_TOKEN,
	SET_TOKEN_ERR,
	SET_CREDENTIALS,
	SET_SESSION,
} from '../constants/action-types';

export function setEmail(payload) {
	return { type: SET_EMAIL, payload };
}
export function setEmailErr(payload) {
	return { type: SET_EMAIL_ERR, payload };
}
export function setPassword(payload) {
	return { type: SET_PASSWORD, payload };
}
export function setPasswordErr(payload) {
	return { type: SET_PASSWORD_ERR, payload };
}
export function setFormStep(payload) {
	return { type: SET_FORM_STEP, payload };
}
export function setToken(payload) {
	return { type: SET_TOKEN, payload };
}
export function setTokenErr(payload) {
	return { type: SET_TOKEN_ERR, payload };
}
export function setCredentials(payload) {
	return { type: SET_CREDENTIALS, payload };
}
export function setSession(payload) {
	return { type: SET_SESSION, payload };
}
