import React from 'react';
import PropTypes from 'prop-types';

export const Alert = (props) => {
	const { type, message } = props;
	return (
		<>
			{type && message && (
				<div className={`alert ${type === 'success' ? 'success' : 'error'}`}>
					<p>
						{type === 'success' ? `Success! ${message}` : `Error! ${message}`}
					</p>
				</div>
			)}
		</>
	);
};

Alert.propTypes = {
	type: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
};

export default Alert;
