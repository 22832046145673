import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

function Header(props) {
	const { pageTitle } = props;
	const history = useHistory();

	return (
		<div className="header">
			<h1 className="header__title">{pageTitle}</h1>
			<button className="header__backBtn" onClick={history.goBack}>
				<FontAwesomeIcon
					icon={faChevronLeft}
					className="header__backBtn--icon"
				/>
				<span className="header__backBtn--text">Go back</span>
			</button>
		</div>
	);
}

const mapStateToProps = (state) => ({
	pageTitle: state.RootReducer.pageTitle,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
