import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from './elements/Button';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	toggleDeleteModal,
	setSelectedCategory,
} from '../redux/actions/categoriesActions';
import { setAlert } from '../redux/actions/index';

function DeleteModal(props) {
	const {
		text,
		url,
		errorMessage,
		toggleDeleteModal,
		setAlert,
		redirectURL,
	} = props;

	const history = useHistory();

	const handleDelete = (e) => {
		e.preventDefault();
		axios
			.delete(url, {
				withCredentials: true,
			})
			.then(() => {
				toggleDeleteModal(false);
				if (redirectURL) {
					history.push(redirectURL);
				} else {
					history.go();
				}
			})
			.catch(() => {
				setAlert({ type: 'error', message: errorMessage });
				setTimeout(() => {
					setAlert({
						type: '',
						message: '',
					});
				}, 2500);
			});
	};

	return (
		<div className="deleteModal">
			<div className="deleteModal__backdrop"></div>
			<div className="deleteModal__window">
				<span
					className="deleteModal__window--closeIcon"
					onClick={() => {
						toggleDeleteModal(false);
					}}
				>
					<FontAwesomeIcon icon={faTimes} />
				</span>
				<p className="deleteModal__window--text">
					Are you sure you want to <em>delete</em> this {text}
				</p>
				<div className="deleteModal__window--buttons">
					<Button type="button" className="blueButton" onClick={handleDelete}>
						Yes
					</Button>
					<Button
						type="button"
						className="greyButton"
						onClick={() => {
							toggleDeleteModal(false);
						}}
					>
						No
					</Button>
				</div>
			</div>
		</div>
	);
}

const mapDispatchToProps = (dispatch) => ({
	toggleDeleteModal: (bool) => dispatch(toggleDeleteModal(bool)),
	setSelectedCategory: (string) => dispatch(setSelectedCategory(string)),
	setAlert: (obj) => dispatch(setAlert(obj)),
});

DeleteModal.propTypes = {
	text: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	errorMessage: PropTypes.string.isRequired,
};

export default connect(null, mapDispatchToProps)(DeleteModal);
