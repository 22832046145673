import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import SingleCategory from '../components/SingleCategory';
import AddButton from '../components/elements/AddButton';
import DeleteModal from '../components/DeleteModal';

import {
	setCurrentPage,
	setPageTitle,
	setLoading,
} from '../redux/actions/index';
import { loadCategories } from '../redux/actions/categoriesActions';

function Categories(props) {
	const {
		setCurrentPage,
		setPageTitle,
		setLoading,
		loadCategories,
		categories,
		showDeleteModal,
		selectedCategory,
	} = props;

	useEffect(() => {
		setLoading(true);
		setCurrentPage('categories');
		setPageTitle('Categories');
		loadCategories();
		setLoading(false);
	}, [setCurrentPage, setPageTitle, setLoading, loadCategories]);

	const API_URL = process.env.REACT_APP_API_URL;

	return (
		<div className="categories">
			{/* Delete Modal */}
			{showDeleteModal && (
				<DeleteModal
					text="category and ALL the Phrases in it?"
					url={`${API_URL}/categories/${selectedCategory}`}
					errorMessage="Category could not be deleted."
				/>
			)}
			{/* Categories list */}
			{categories.length === 0 ? (
				<p>No categories found.</p>
			) : (
				categories.map((category) => (
					<SingleCategory key={category._id} category={category} />
				))
			)}
			{/* Add Button */}
			<AddButton text="Add new category" link="/categories/create" />
		</div>
	);
}

const mapStateToProps = (state) => ({
	loading: state.RootReducer.loading,
	session: state.AuthReducer.session,
	categories: state.CategoriesReducer.categories,
	showDeleteModal: state.CategoriesReducer.showDeleteModal,
	selectedCategory: state.CategoriesReducer.selectedCategory,
});

const mapDispatchToProps = (dispatch) => ({
	setCurrentPage: (string) => dispatch(setCurrentPage(string)),
	setPageTitle: (string) => dispatch(setPageTitle(string)),
	setLoading: (bool) => dispatch(setLoading(bool)),
	loadCategories: () => dispatch(loadCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
