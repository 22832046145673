import React from 'react';
import PropTypes from 'prop-types';

function Textarea(props) {
	const {
		name,
		id,
		label,
		className,
		placeholder,
		value,
		onChange,
		error,
	} = props;
	return (
		<div className="textarea__wrapper">
			<label htmlFor={id} className="textarea__label">
				{label}
			</label>
			<textarea
				name={name}
				id={id}
				className={`textarea ${className && className}`}
				onChange={onChange}
				placeholder={placeholder}
				value={value}
			></textarea>
			{error && <p className="inputElement__error">{error}</p>}
		</div>
	);
}

Textarea.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	error: PropTypes.string,
	className: PropTypes.string,
};

export default Textarea;
