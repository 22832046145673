import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { searchUsers, setSearchKeyword } from '../redux/actions/usersActions';

function SearchUsers(props) {
	const { users, searchUsers, searchKeyword, setSearchKeyword } = props;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const filterUsers = (keyword) => {
		setSearchKeyword(keyword);
		const filteredUsers =
			keyword === ''
				? users
				: users.filter((user) => user.email.includes(searchKeyword));
		searchUsers(filteredUsers);
	};

	useEffect(() => {
		searchUsers(users);

		if (searchKeyword !== '') {
			filterUsers(searchKeyword);
		}
	}, [searchUsers, users, searchKeyword, setSearchKeyword, filterUsers]);

	return (
		<div className="searchUsers">
			<input
				className="searchUsers__input"
				value={searchKeyword}
				onChange={(e) => filterUsers(e.target.value)}
				placeholder="Search..."
			/>
		</div>
	);
}

const mapStateToProps = (state) => ({
	users: state.UsersReducer.users,
	searchKeyword: state.UsersReducer.searchKeyword,
});

const mapDispatchToProps = (dispatch) => ({
	searchUsers: (array) => dispatch(searchUsers(array)),
	setSearchKeyword: (string) => dispatch(setSearchKeyword(string)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchUsers);
