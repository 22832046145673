import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({
	component: Component,
	loading,
	session,
	status,
	...rest
}) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				session && !loading ? (
					<Component {...props} status={status} />
				) : (
					<Redirect to="/login" />
				)
			}
		/>
	);
};

const mapStateToProps = (state) => ({
	session: state.AuthReducer.session,
	loading: state.RootReducer.loading,
});

export default connect(mapStateToProps)(PrivateRoute);
