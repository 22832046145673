import React from 'react';
import { connect } from 'react-redux';

import EmailForm from '../components/auth/EmailForm';
import TokenForm from '../components/auth/TokenForm';
import Alert from '../components/Alert';
import Loading from '../components/Loading';

function Login(props) {
	const { alert, formStep, loading } = props;

	return loading ? (
		<Loading />
	) : (
		<div className="loginPage">
			<div className="loginPage__container">
				<Alert type={alert.type} message={alert.message} />
				{/* Header */}
				<div className="loginPage__header">
					<div className="loginPage__header--logo"></div>
					<h1 className="loginPage__header--title">Comms4Docs</h1>
					<h3 className="loginPage__header--description">
						{formStep === 1
							? 'Welcome! Please authenticate into the platform using your account.'
							: '2 Factor Authentication'}
					</h3>
				</div>
				{/* Form */}
				{formStep === 1 ? <EmailForm /> : <TokenForm />}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	alert: state.RootReducer.alert,
	formStep: state.AuthReducer.formStep,
	loading: state.RootReducer.loading,
	session: state.AuthReducer.session,
});

export default connect(mapStateToProps)(Login);
