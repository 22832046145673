import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from './elements/Button';
import { faBookOpen, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';

import {
	toggleDeleteModal,
	setSelectedCategory,
} from '../redux/actions/categoriesActions';

function SingleCategory(props) {
	const { category, toggleDeleteModal, setSelectedCategory } = props;
	const { _id, title } = category;

	const history = useHistory();
	return (
		<div className="singleCategory">
			<p className="singleCategory__title">{title}</p>
			<div className="singleCategory__rightSide">
				<Button
					className="textLink hoverAccent"
					icon={faBookOpen}
					onClick={() => history.push(`/categories/${_id}/phrases`)}
				>
					View Phrases
				</Button>
				<Button
					className="textLink hoverEdit"
					icon={faEdit}
					onClick={() => history.push(`/categories/${_id}/edit`)}
				>
					Edit
				</Button>
				<Button
					className="textLink hoverDelete"
					icon={faTimes}
					onClick={() => {
						setSelectedCategory(_id);
						toggleDeleteModal(true);
					}}
				>
					Delete
				</Button>
			</div>
		</div>
	);
}

const mapDispatchToProps = (dispatch) => ({
	toggleDeleteModal: (payload) => dispatch(toggleDeleteModal(payload)),
	setSelectedCategory: (payload) => dispatch(setSelectedCategory(payload)),
});

export default connect(null, mapDispatchToProps)(SingleCategory);
