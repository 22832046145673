import {
	SET_SELECTED_CATEG,
	LOAD_PHRASES,
	SET_SELECTED_PHRASE,
	TOGGLE_DELETE_MODAL,
	SET_PHRASE,
	SET_PHRASE_ERROR,
	LOAD_PHRASE,
	LOAD_ALL_PHRASES,
} from '../constants/action-types';

import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export function setSelectedCateg(payload) {
	return { type: SET_SELECTED_CATEG, payload };
}
export function loadPhrases(payload) {
	return function (dispatch) {
		return axios
			.get(`${API_URL}/phrases/category/${payload}`, {
				withCredentials: true,
			})
			.then((response) =>
				response.status === 200 ? response.data.phrases : []
			)
			.then((payload) => {
				dispatch({ type: LOAD_PHRASES, payload });
			});
	};
}
export function loadAllPhrases() {
	return function (dispatch) {
		return axios
			.get(`${API_URL}/phrases`, {
				withCredentials: true,
			})
			.then((response) =>
				response.status === 200 ? response.data.phrases : []
			)
			.then((payload) => {
				dispatch({ type: LOAD_ALL_PHRASES, payload });
			});
	};
}
export function setSelectedPhrase(payload) {
	return { type: SET_SELECTED_PHRASE, payload };
}
export function toggleDeleteModal(payload) {
	return { type: TOGGLE_DELETE_MODAL, payload };
}
export function setPhrase(payload) {
	return { type: SET_PHRASE, payload };
}
export function setPhraseError(payload) {
	return { type: SET_PHRASE_ERROR, payload };
}
export function loadPhrase(payload) {
	return function (dispatch) {
		return axios
			.get(`${API_URL}/phrases/${payload}`, {
				withCredentials: true,
			})
			.then((response) => (response.status === 200 ? response.data.phrase : []))
			.then((payload) => {
				dispatch({ type: LOAD_PHRASE, payload });
			});
	};
}
