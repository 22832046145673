// export function addArticle(payload) {
// 	return { type: ADD_ARTICLE, payload };
// }

import {
	TOGGLE_SIDEBAR,
	SET_CURRENT_PAGE,
	SET_PAGE_TITLE,
	SET_LOADING,
	SET_ALERT,
} from '../constants/action-types';

export function toggleSidebar(payload) {
	return { type: TOGGLE_SIDEBAR, payload };
}
export function setCurrentPage(payload) {
	return { type: SET_CURRENT_PAGE, payload };
}
export function setPageTitle(payload) {
	return { type: SET_PAGE_TITLE, payload };
}
export function setLoading(payload) {
	return { type: SET_LOADING, payload };
}
export function setAlert(payload) {
	return { type: SET_ALERT, payload };
}
