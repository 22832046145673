import {
	LOAD_CATEGORIES,
	LOAD_CATEGORY,
	SET_CATEG_NAME,
	SET_CATEG_NAME_ERR,
	SET_CATEG_AVAIL,
	SET_CONV_BODY,
	SET_CONV_IMAGE,
	SET_CONV_AUDIO,
	TOGGLE_DELETE_MODAL,
	SET_SELECTED_CATEGORY,
} from '../constants/action-types';

const initialState = {
	categories: [],
	name: '',
	nameError: '',
	premiumOnly: false,
	conversationBody: '',
	conversationImage: '',
	conversationAudio: '',
	showDeleteModal: false,
	selectedCategory: '',
};

function CategoriesReducer(state = initialState, action) {
	switch (action.type) {
		case LOAD_CATEGORIES:
			return { ...state, categories: action.payload };
		case LOAD_CATEGORY:
			return {
				...state,
				name: action.payload.title,
				premiumOnly: action.payload.subscription === 'premium' ? true : false,
				conversationBody: action.payload.conversation_body,
				conversationImage: action.payload.conversation_image,
				conversationAudio: action.payload.conversation_audio,
			};
		case SET_CATEG_NAME:
			return { ...state, name: action.payload };
		case SET_CATEG_NAME_ERR:
			return { ...state, nameError: action.payload };
		case SET_CATEG_AVAIL:
			return { ...state, premiumOnly: action.payload };
		case SET_CONV_BODY:
			return { ...state, conversationBody: action.payload };
		case SET_CONV_IMAGE:
			return { ...state, conversationImage: action.payload };
		case SET_CONV_AUDIO:
			return { ...state, conversationAudio: action.payload };
		case TOGGLE_DELETE_MODAL:
			return { ...state, showDeleteModal: action.payload };
		case SET_SELECTED_CATEGORY:
			return { ...state, selectedCategory: action.payload };
		default:
			return state;
	}
}

export default CategoriesReducer;
