import {
	LOAD_QUESTIONS,
	TOGGLE_DELETE_MODAL,
	SET_SELECTED_QUESTION,
	LOAD_SINGLE_QUESTION,
} from '../constants/action-types';

const initialState = {
	questions: [],
	showDeleteModal: false,
	selectedQuestion: '',
	question: null,
};

function FaqReducer(state = initialState, action) {
	switch (action.type) {
		case LOAD_QUESTIONS:
			return { ...state, questions: action.payload };
		case TOGGLE_DELETE_MODAL:
			return { ...state, showDeleteModal: action.payload };
		case SET_SELECTED_QUESTION:
			return { ...state, selectedQuestion: action.payload };
		case LOAD_SINGLE_QUESTION:
			return { ...state, question: action.payload };
		default:
			return state;
	}
}

export default FaqReducer;
