// Example Actions
export const ADD_ARTICLE = 'ADD_ARTICLE';
export const DATA_LOADED = 'DATA_LOADED';

// Root Actions
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_LOADING = 'SET_LOADING';
export const SET_ALERT = 'SET_ALERT';

// Auth Actions
export const SET_EMAIL = 'SET_EMAIL';
export const SET_EMAIL_ERR = 'SET_EMAIL_ERR';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_PASSWORD_ERR = 'SET_PASSWORD_ERR';
export const SET_FORM_STEP = 'SET_FORM_STEP';
export const SET_CREDENTIALS = 'SET_CREDENTIALS';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_TOKEN_ERR = 'SET_TOKEN_ERR';
export const SET_SESSION = 'SET_SESSION';

// Categories Actions
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const LOAD_CATEGORY = 'LOAD_CATEGORY';
export const SET_CATEG_NAME = 'SET_CATEG_NAME';
export const SET_CATEG_NAME_ERR = 'SET_CATEG_NAME_ERR';
export const SET_CATEG_AVAIL = 'SET_CATEG_AVAIL';
export const SET_CONV_BODY = 'SET_CONV_BODY';
export const SET_CONV_IMAGE = 'SET_CONV_IMAGE';
export const SET_CONV_AUDIO = 'SET_CONV_AUDIO';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const TOGGLE_DELETE_MODAL = 'TOGGLE_DELETE_MODAL';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';

// Phrases Actions
export const LOAD_ALL_PHRASES = 'LOAD_ALL_PHRASES';
export const LOAD_PHRASES = 'LOAD_PHRASES';
export const SET_SELECTED_CATEG = 'SET_SELECTED_CATEG';
export const SET_SELECTED_PHRASE = 'SET_SELECTED_PHRASE';
export const SET_PHRASE = 'SET_PHRASE';
export const SET_PHRASE_ERROR = 'SET_PHRASE_ERROR';
export const LOAD_PHRASE = 'LOAD_PHRASE';

// Users Actions
export const LOAD_USERS = 'LOAD_USERS';
export const SEARCH_USERS = 'SEARCH_USERS';
export const LOAD_SINGLE_USER = 'LOAD_SINGLE_USER';
export const SET_SEARCH_KEYWORD = 'SET_SEARCH_KEYWORD';
export const LOAD_USERS_STATS = 'LOAD_USERS_STATS';

// Question Actions
export const LOAD_QUESTIONS = 'LOAD_QUESTIONS';
export const SET_SELECTED_QUESTION = 'SET_SELECTED_QUESTION';
export const LOAD_SINGLE_QUESTION = 'LOAD_SINGLE_QUESTION';
