import {
	LOAD_USERS,
	SEARCH_USERS,
	LOAD_SINGLE_USER,
	SET_SEARCH_KEYWORD,
	TOGGLE_DELETE_MODAL,
	LOAD_USERS_STATS,
} from '../constants/action-types';

const initialState = {
	users: [],
	searchResults: [],
	singleUser: null,
	searchKeyword: '',
	showDeleteModal: false,
	usersStats: [],
};

function UsersReducer(state = initialState, action) {
	switch (action.type) {
		case LOAD_USERS:
			return { ...state, users: action.payload };
		case SEARCH_USERS:
			return { ...state, searchResults: action.payload };
		case LOAD_SINGLE_USER:
			return { ...state, singleUser: action.payload };
		case SET_SEARCH_KEYWORD:
			return { ...state, searchKeyword: action.payload };
		case TOGGLE_DELETE_MODAL:
			return { ...state, showDeleteModal: action.payload };
		case LOAD_USERS_STATS:
			return { ...state, usersStats: action.payload };
		default:
			return state;
	}
}

export default UsersReducer;
