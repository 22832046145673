import React from 'react';
import PropTypes from 'prop-types';

function Input(props) {
	const {
		type,
		id,
		name,
		label,
		value,
		onChange,
		error,
		className,
		placeholder,
	} = props;
	return (
		<div className="formElement">
			{label && (
				<label htmlFor={name} className="inputElement__label">
					{label}
				</label>
			)}
			<input
				type={type}
				id={id}
				name={name}
				value={value}
				placeholder={placeholder}
				onChange={onChange}
				className={`inputElement${className ? className : ''}`}
			/>
			{error && <p className="inputElement__error">{error}</p>}
		</div>
	);
}

Input.propTypes = {
	type: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	error: PropTypes.string.isRequired,
	className: PropTypes.string,
};

export default Input;
