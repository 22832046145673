import {
	LOAD_USERS,
	SEARCH_USERS,
	LOAD_SINGLE_USER,
	SET_SEARCH_KEYWORD,
	TOGGLE_DELETE_MODAL,
	LOAD_USERS_STATS,
} from '../constants/action-types';

import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export function loadUsers() {
	return function (dispatch) {
		return axios
			.get(`${API_URL}/users/`, {
				withCredentials: true,
			})
			.then((response) => (response.status === 200 ? response.data.users : []))
			.then((payload) => {
				dispatch({ type: LOAD_USERS, payload });
			});
	};
}
export function searchUsers(payload) {
	return { type: SEARCH_USERS, payload };
}
export function loadSingleUser(payload) {
	return function (dispatch) {
		return axios
			.get(`${API_URL}/users/${payload}`, {
				withCredentials: true,
			})
			.then((response) => (response.status === 200 ? response.data.user : []))
			.then((payload) => {
				dispatch({ type: LOAD_SINGLE_USER, payload });
			});
	};
}
export function setSearchKeyword(payload) {
	return { type: SET_SEARCH_KEYWORD, payload };
}
export function toggleDeleteModal(payload) {
	return { type: TOGGLE_DELETE_MODAL, payload };
}
export function loadUserStats() {
	return function (dispatch) {
		return axios
			.get(`${API_URL}/users/lastweek/`, {
				withCredentials: true,
			})
			.then((response) => (response.status === 200 ? response.data.stats : []))
			.then((payload) => {
				dispatch({ type: LOAD_USERS_STATS, payload });
			});
	};
}
