import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import SinglePhrase from '../components/SinglePhrase';
import AddButton from '../components/elements/AddButton';
import DeleteModal from '../components/DeleteModal';

import {
	setCurrentPage,
	setPageTitle,
	setLoading,
} from '../redux/actions/index';
import { setSelectedCateg, loadPhrases } from '../redux/actions/phrasesActions';

function Phrases(props) {
	const {
		setCurrentPage,
		setPageTitle,
		setLoading,
		setSelectedCateg,
		loadPhrases,
		phrases,
		showDeleteModal,
		selectedPhrase,
	} = props;

	const API_URL = process.env.REACT_APP_API_URL;
	const categoryID = props.match.params.id;

	useEffect(() => {
		setLoading(true);
		setCurrentPage('categories');
		setPageTitle('Phrases');
		setSelectedCateg(categoryID);
		loadPhrases(categoryID);
		setLoading(false);
	}, [
		setCurrentPage,
		setPageTitle,
		setLoading,
		loadPhrases,
		categoryID,
		setSelectedCateg,
	]);

	return (
		<div className="categories">
			{/* Delete Modal */}
			{showDeleteModal && (
				<DeleteModal
					text="phrase?"
					url={`${API_URL}/phrases/${selectedPhrase}`}
					errorMessage="Phrase could not be deleted."
				/>
			)}
			{/* Categories list */}
			{phrases.length === 0 ? (
				<p>No phrases found.</p>
			) : (
				phrases.map((phrase) => (
					<SinglePhrase phrase={phrase} key={phrase._id} />
					// <SingleCategory key={category._id} category={category} />
				))
			)}
			{/* Add Button */}
			<AddButton
				text="Add new phrase"
				link={`/categories/${categoryID}/phrases/create`}
			/>
		</div>
	);
}

const mapStateToProps = (state) => ({
	loading: state.RootReducer.loading,
	phrases: state.PhrasesReducer.phrases,
	showDeleteModal: state.PhrasesReducer.showDeleteModal,
	selectedPhrase: state.PhrasesReducer.selectedPhrase,
});

const mapDispatchToProps = (dispatch) => ({
	setCurrentPage: (string) => dispatch(setCurrentPage(string)),
	setPageTitle: (string) => dispatch(setPageTitle(string)),
	setLoading: (bool) => dispatch(setLoading(bool)),
	loadPhrases: (string) => dispatch(loadPhrases(string)),
	setSelectedCateg: (string) => dispatch(setSelectedCateg(string)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Phrases);
