import React from 'react';
import { connect } from 'react-redux';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Alert from '../components/Alert';
import Loading from '../components/Loading';

function MainLayout(props) {
	const { openSidebar, alert, loading } = props;

	return loading ? (
		<Loading />
	) : (
		<div className="mainLayout">
			<Alert type={alert.type} message={alert.message} />
			<Sidebar layout="main" />
			<div className={`contentContainer ${!openSidebar && 'ml-0'}`}>
				<Header />
				{props.children}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	openSidebar: state.RootReducer.openSidebar,
	loading: state.RootReducer.loading,
	alert: state.RootReducer.alert,
});

export default connect(mapStateToProps)(MainLayout);
