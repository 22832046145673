import {
	LOAD_PHRASES,
	SET_SELECTED_CATEG,
	TOGGLE_DELETE_MODAL,
	SET_SELECTED_PHRASE,
	SET_PHRASE,
	SET_PHRASE_ERROR,
	LOAD_PHRASE,
	LOAD_ALL_PHRASES,
} from '../constants/action-types';

const initialState = {
	phrases: [],
	phrase: '',
	phraseError: '',
	showDeleteModal: false,
	selectedCateg: '',
	allPhrases: [],
};

function PhrasesReducer(state = initialState, action) {
	switch (action.type) {
		case LOAD_PHRASES:
			return { ...state, phrases: action.payload };
		case SET_SELECTED_CATEG:
			return { ...state, selectedCateg: action.payload };
		case SET_SELECTED_PHRASE:
			return { ...state, selectedPhrase: action.payload };
		case TOGGLE_DELETE_MODAL:
			return { ...state, showDeleteModal: action.payload };
		case SET_PHRASE:
			return { ...state, phrase: action.payload };
		case SET_PHRASE_ERROR:
			return { ...state, phraseError: action.payload };
		case LOAD_PHRASE:
			return { ...state, phrase: action.payload.body };
		case LOAD_ALL_PHRASES:
			return { ...state, allPhrases: action.payload };
		default:
			return state;
	}
}

export default PhrasesReducer;
