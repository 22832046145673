import React from 'react';

function DeleteAccount() {
  // Inline styles for consistent styling
  const containerStyle = {
    lineHeight: '1.5',
    fontFamily: 'Arial, sans-serif',
    color: '#333'
  };

  const paragraphStyle = {
    fontSize: 'inherit',
    lineHeight: 'inherit'
  };

  const emailLinkStyle = {
    color: 'blue',
    fontSize: 'inherit'
  };

  const boldStyle = {
    fontWeight: 'bold',
    fontSize: '1.1em'
  };

  return (
    // Wrapping div with a class name for styling purposes
    <div className="privacyPolicy">
      {/* Title for the page */}
      <h1 className="privacyPolicy__title">Instructions</h1>

      {/* Container for the main content */}
      <div style={containerStyle}>
        {/* Paragraph with instructions */}
        <p style={paragraphStyle}>
          Welcome to our Account Deletion Options page. If you wish to delete your Comms4Docs account, you have two convenient options available: You can directly delete it from the settings within the Comms4Docs mobile application, resulting in immediate deletion. Alternatively, you can initiate the deletion process by sending an email to <a href="mailto:comms4docs@gmail.com" style={emailLinkStyle}>comms4docs@gmail.com</a> from your registered email account with the subject "Account Deletion Request".
        </p>
        <p style={paragraphStyle}>
          Please note that upon the deletion of your account, all associated user and profile data will be permanently erased and cannot be recovered. Additionally, we will ensure the deletion of your data within 14 days following the deletion request via email for operational purposes.
        </p>
        <p style={paragraphStyle}>
          If you are certain you want to proceed with the deletion, please choose one of the provided methods. Should you have any questions about the account deletion process, please do not hesitate to reach out. We are here to assist you every step of the way.
        </p>
        <p style={paragraphStyle}>
          Sincerely,<br />
          <span style={boldStyle}>Comms4Docs Team</span>
        </p>
      </div>
    </div>
  );
}

export default DeleteAccount;
