import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import CategoriesReducer from './CategoriesReducer';
import PhrasesReducer from './PhrasesReducer';
import UsersReducer from './UsersReducer';
import FaqReducer from './FaqReducer';

// Root Reducer
import {
	TOGGLE_SIDEBAR,
	SET_CURRENT_PAGE,
	SET_PAGE_TITLE,
	SET_LOADING,
	SET_ALERT,
} from '../constants/action-types';

const initialState = {
	openSidebar: true,
	currentPage: '',
	pageTitle: '',
	loading: true,
	alert: {
		type: '',
		message: '',
	},
};

function RootReducer(state = initialState, action) {
	switch (action.type) {
		case TOGGLE_SIDEBAR:
			return { ...state, openSidebar: action.payload };
		case SET_CURRENT_PAGE:
			return { ...state, currentPage: action.payload };
		case SET_PAGE_TITLE:
			return { ...state, pageTitle: action.payload };
		case SET_LOADING:
			return { ...state, loading: action.payload };
		case SET_ALERT:
			return {
				...state,
				alert: {
					...state.alert,
					type: action.payload.type,
					message: action.payload.message,
				},
			};
		default:
			return state;
	}
}

// Combine all reducers
export default combineReducers({
	RootReducer,
	AuthReducer,
	CategoriesReducer,
	PhrasesReducer,
	UsersReducer,
	FaqReducer,
});
