import React from 'react';
import { connect } from 'react-redux';

import Sidebar from '../components/Sidebar';
import Loading from '../components/Loading';

function PublicLayout(props) {
	const { openSidebar, loading } = props;

	return loading ? (
		<Loading />
	) : (
		<div className="mainLayout">
			<Sidebar layout="public" title={props.title} />
			<div className={`contentContainer ${!openSidebar && 'ml-0'}`}>
				{props.children}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	openSidebar: state.RootReducer.openSidebar,
	loading: state.RootReducer.loading,
});

export default connect(mapStateToProps)(PublicLayout);
