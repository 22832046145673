import React from 'react';
import Button from './elements/Button';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';

import {
	toggleDeleteModal,
	setSelectedPhrase,
} from '../redux/actions/phrasesActions';

function SinglePhrase(props) {
	const { phrase, setSelectedPhrase, toggleDeleteModal, selectedCateg } = props;

	const history = useHistory();

	return (
		<div className="singlePhrase">
			<p className="singlePhrase__text">{phrase.body}</p>
			<div className="singlePhrase__buttons">
				<Button
					type="button"
					icon={faEdit}
					className="textLink hoverEdit"
					onClick={() =>
						history.push(
							`/categories/${selectedCateg}/phrases/${phrase._id}/edit`
						)
					}
				>
					Edit
				</Button>
				<Button
					type="button"
					icon={faTimes}
					className="textLink hoverDelete"
					onClick={() => {
						toggleDeleteModal(true);
						setSelectedPhrase(phrase._id);
					}}
				>
					Delete
				</Button>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	selectedCateg: state.PhrasesReducer.selectedCateg,
});

const mapDispatchToProps = (dispatch) => ({
	setSelectedPhrase: (payload) => dispatch(setSelectedPhrase(payload)),
	toggleDeleteModal: (payload) => dispatch(toggleDeleteModal(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SinglePhrase);
