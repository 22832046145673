import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '../components/elements/Button';
import Alert from '../components/Alert';
import Input from '../components/elements/Input';
import Switch from 'react-switch';

// prettier-ignore
import { setCurrentPage, setPageTitle, setLoading, setAlert} from '../redux/actions/index';
import { loadSingleUser } from '../redux/actions/usersActions';

function EditUser(props) {
	const {
		setCurrentPage,
		setPageTitle,
		setLoading,
		alert,
		setAlert,
		singleUser,
		loadSingleUser,
	} = props;

	const [name, setName] = useState('');
	const [nameErr, setNameErr] = useState('');
	const [email, setEmail] = useState('');
	const [emailErr, setEmailErr] = useState('');
	const [grade, setGrade] = useState('');
	const [gradeErr, setGradeErr] = useState('');
	const [speciality, setSpeciality] = useState('');
	const [specialityErr, setSpecialityErr] = useState('');
	const [newPassword, setNewPassword] = useState('********');
	const [newPasswordErr, setNewPasswordErr] = useState('');
	const [premiumUser, setPremiumUser] = useState(false);

	const id = props.match.params.id;

	useEffect(() => {
		setLoading(true);
		setCurrentPage('users');
		setPageTitle('Edit user');
		loadSingleUser(id);
		setLoading(false);
	}, [setLoading, setCurrentPage, setPageTitle, loadSingleUser, id]);

	useEffect(() => {
		if (singleUser) {
			setName(singleUser.name);
			setEmail(singleUser.email);
			setGrade(singleUser.grade);
			setSpeciality(singleUser.speciality);
			setPremiumUser(singleUser.subscription === 'premium' ? true : false);
		}
	}, [singleUser]);

	const history = useHistory();
	const API_URL = process.env.REACT_APP_API_URL;

	const handleSubmit = (e) => {
		e.preventDefault();
		let activeErrors = false;
		const nameRegex = /^[a-zA-Z0-9_ ]+$/;
		const emailRegex = /\S+@\S+\.\S+/;
		// Name validation
		if (name === '') {
			setNameErr("Error! Name can't be left empty.");
			activeErrors = true;
		} else if (!nameRegex.test(name)) {
			setNameErr('Error! Invalid name.');
			activeErrors = true;
		} else {
			setNameErr('');
			activeErrors = false;
		}
		// Email validation
		if (email === '') {
			setEmailErr("Error! Email can't be left empty");
			activeErrors = true;
		} else if (!emailRegex.test(email)) {
			setEmailErr('Error! Invalid email format.');
			activeErrors = true;
		} else {
			setEmailErr('');
			activeErrors = false;
		}
		// Grade validation
		if (grade === '') {
			setGradeErr("Error! Grade can't be left empty");
			activeErrors = true;
		} else if (!nameRegex.test(grade)) {
			setGradeErr('Error! Invalid grade format.');
			activeErrors = true;
		} else {
			setGradeErr('');
			activeErrors = false;
		}
		// Specialty validation
		if (speciality === '') {
			setSpecialityErr("Error! Speciality can't be left empty");
			activeErrors = true;
		} else if (!nameRegex.test(speciality)) {
			setSpecialityErr('Error! Invalid specialty format.');
			activeErrors = true;
		} else {
			setSpecialityErr('');
			activeErrors = false;
		}
		// Password validation
		if (newPassword !== '********') {
			if (newPassword.length < 6) {
				setNewPasswordErr(
					'Error! The new password must have 6 characters or more.'
				);
				activeErrors = true;
			} else if (newPassword.search(/[a-z]/) < 0) {
				setNewPasswordErr(
					'Error! The new password must have at least 1 lower case letter.'
				);
				activeErrors = true;
			} else if (newPassword.search(/[A-Z]/) < 0) {
				setNewPasswordErr(
					'Error! The new password must have at least 1 upper case letter.'
				);
				activeErrors = true;
			} else {
				setNewPasswordErr('');
				activeErrors = false;
			}
		}
		// Submit
		if (!activeErrors) {
			const user = { name, email, grade, speciality };
			if (newPassword !== '********') {
				user.password = newPassword;
			}
			user.subscription = premiumUser ? 'premium' : 'free';
			axios
				.put(`${API_URL}/users/${id}`, user, {
					withCredentials: true,
				})
				.then(() => {
					setAlert({ type: 'success', message: 'User updated' });
					setTimeout(() => {
						history.push(`/users/${id}`);
						setAlert({
							type: '',
							message: '',
						});
					}, 2500);
				})
				.catch(() => {
					setAlert({ type: 'error', message: 'Something went wrong.' });
					setTimeout(() => {
						setAlert({
							type: '',
							message: '',
						});
					}, 2500);
				});
		}
	};

	return (
		<div className="editUser">
			<Alert type={alert.type} message={alert.message} />
			<form onSubmit={handleSubmit} noValidate>
				{/* Name */}
				<Input
					type="text"
					id="name"
					name="name"
					value={name}
					onChange={(e) => setName(e.target.value)}
					label="name"
					placeholder="User name"
					error={nameErr}
				/>
				{/* Email */}
				<Input
					type="email"
					id="email"
					name="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					label="Email"
					placeholder="User email"
					error={emailErr}
				/>
				{/* Grade */}
				<Input
					type="text"
					id="grade"
					name="grade"
					value={grade}
					onChange={(e) => setGrade(e.target.value)}
					label="Grade"
					placeholder="User grade"
					error={gradeErr}
				/>
				{/* Speciality */}
				<Input
					type="text"
					id="speciality"
					name="speciality"
					value={speciality}
					onChange={(e) => setSpeciality(e.target.value)}
					label="Specialty"
					placeholder="User specialty"
					error={specialityErr}
				/>
				{/* New password */}
				<Input
					type="password"
					id="password"
					name="password"
					value={newPassword}
					onChange={(e) => setNewPassword(e.target.value)}
					label="New password"
					placeholder="New user password"
					error={newPasswordErr}
				/>
				{/* Subscription */}
				<p className="mb-3">Premium user</p>
				<Switch
					onChange={() => setPremiumUser(!premiumUser)}
					checked={premiumUser}
					checkedIcon={false}
					uncheckedIcon={false}
					onColor="#0b1542"
					offColor="#a4b0b6"
					name="subscription"
				/>

				<div className="d-flex my-5 saveCancelButtons">
					<Button type="submit" className="blueButton">
						Save
					</Button>
					<Button
						type="button"
						className="textLink faded hoverBlack ml-4"
						onClick={() => history.push(`/users/${singleUser._id}`)}
					>
						Cancel
					</Button>
				</div>
			</form>
		</div>
	);
}

const mapStateToProps = (state) => ({
	loading: state.RootReducer.loading,
	alert: state.RootReducer.alert,

	singleUser: state.UsersReducer.singleUser,
});
const mapDispatchToProps = (dispatch) => ({
	setCurrentPage: (string) => dispatch(setCurrentPage(string)),
	setPageTitle: (string) => dispatch(setPageTitle(string)),
	setLoading: (bool) => dispatch(setLoading(bool)),
	setAlert: (obj) => dispatch(setAlert(obj)),

	loadSingleUser: (string) => dispatch(loadSingleUser(string)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
