import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Button(props) {
	const { onClick, className, icon, type, ...rest } = props;
	return (
		<React.Fragment>
			<button
				{...rest}
				type={type}
				onClick={onClick}
				className={`buttonElement ${className}`}
			>
				{icon && (
					<FontAwesomeIcon icon={icon} className="buttonElement__icon" />
				)}
				{props.children}
			</button>
		</React.Fragment>
	);
}

Button.propTypes = {
	onClick: PropTypes.func,
	className: PropTypes.string,
	icon: PropTypes.object,
	type: PropTypes.string,
};

export default Button;
