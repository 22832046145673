import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function DataCard(props) {
	const { label, icon, data } = props;
	return (
		<div className="dataCard">
			<div className="dataCard__info">
				<p className="dataCard__info--label">{label}</p>
				<p className="dataCard__info--data">{data}</p>
			</div>
			{icon && <FontAwesomeIcon icon={icon} className="dataCard__icon" />}
		</div>
	);
}

DataCard.propTypes = {
	label: PropTypes.string.isRequired,
	data: PropTypes.number.isRequired,
	icon: PropTypes.object,
};

export default DataCard;
