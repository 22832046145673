import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from './Button';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

function AddButton(props) {
	const { text, link } = props;
	const history = useHistory();

	return (
		<div className="w-100 text-center">
			<Button
				type="button"
				className="textLink hoverAccent addBtn"
				icon={faPlus}
				onClick={() => history.push(link)}
			>
				{text}
			</Button>
		</div>
	);
}

export default AddButton;
