// Packages
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
// Components
import Input from '../elements/Input';
import Button from '../elements/Button';
// Actions
import { setAlert } from '../../redux/actions';
import {
	setToken,
	setTokenErr,
	setSession,
	setEmail,
	setPassword,
	setFormStep,
} from '../../redux/actions/authActions';

export const TokenForm = (props) => {
	// prettier-ignore
	const {setAlert, token, setToken, tokenErr, setTokenErr, credentials, setSession, setEmail, setPassword, setFormStep} = props;

	const API_URL = process.env.REACT_APP_API_URL;

	const handleSubmit = async (e) => {
		e.preventDefault();
		let activeErrors = false;
		// Token Validation
		if (token === '') {
			setTokenErr('Error! You must insert your token');
			activeErrors = true;
		} else {
			setTokenErr('');
			activeErrors = false;
		}

		// Submit
		if (!activeErrors) {
			await axios
				.post(
					`${API_URL}/auth/verify-token`,
					{
						_id: credentials._id,
						secret: credentials.secret,
						token,
					},
					{ withCredentials: true }
				)
				.then((res) => {
					setAlert({
						type: 'success',
						message: 'Welcome admin.',
					});
					setTimeout(() => {
						// Set redux session after animation
						setSession(res.data.admin);
						// Clear the fields
						setEmail('');
						setPassword('');
						setFormStep(1);
						setAlert({
							type: '',
							message: '',
						});
					}, 2500);
				})
				.catch(() => {
					setAlert({
						type: 'error',
						message: 'Invalid token.',
					});
					setTimeout(
						() =>
							setAlert({
								type: '',
								message: '',
							}),
						2500
					);
				});
		}
	};

	return (
		<form className="loginPage__form" onSubmit={handleSubmit} noValidate>
			{/* 2 Factor Authentication */}
			<Input
				type="text"
				id="2fa_token"
				name="2fa_token"
				label="6-Digit Code"
				placeholder="6-Digit Code"
				value={token}
				onChange={(e) => setToken(e.target.value)}
				error={tokenErr}
			/>
			<div className="text-center">
				<Button type="submit" className="whiteButton mt-4">
					Log In
				</Button>
			</div>
		</form>
	);
};

const mapStateToProps = (state) => ({
	token: state.AuthReducer.token,
	tokenErr: state.AuthReducer.tokenErr,
	credentials: state.AuthReducer.credentials,
});

const mapDispatchToProps = (dispatch) => ({
	setAlert: (obj) => dispatch(setAlert(obj)),
	setToken: (string) => dispatch(setToken(string)),
	setTokenErr: (string) => dispatch(setTokenErr(string)),
	setSession: (obj) => dispatch(setSession(obj)),
	setEmail: (string) => dispatch(setEmail(string)),
	setPassword: (string) => dispatch(setPassword(string)),
	setFormStep: (string) => dispatch(setFormStep(string)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TokenForm);
